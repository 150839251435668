<template>
  <v-container fluid class="login-bkg height-100">
    <v-overlay v-model="loading">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-overlay>
    <v-row :class="$vuetify.breakpoint.xs ? 'pt-75' : 'pt-150'">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="10" md="7" lg="5" xl="4">
        <v-card tile>
          <v-system-bar
            height="60"
            color="primary"
            class="white--text elevation-5"
          >
            <v-card-title>
              <h3>Email Confirmation</h3>
            </v-card-title>
          </v-system-bar>
          <v-system-bar height="5" color="accent" class=""></v-system-bar>
          <v-card-text>
            <span class="text-body-1 text--primary">
              {{ message }}
            </span>
            <div class="pb-2 pt-8" v-if="success">
              <p>You can finish your resume in the original window or tab.</p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import * as login from "../../api/LoginApi";
import * as disp from "../../utility/display";
import * as query from "../../utility/query";
import * as log from "../../utility/logging/logger";

// @ is an alias to /src
export default {
  name: "ConfirmEmailAction",
  data: () => ({
    loading: true,
    message: "Confirming Email...",
    success: false,
    userId: "",
    nonce: "",
    errorText: "",
  }),
  async mounted() {
    try {
      this.nonce = query.GetParameterByName("nonce");
      this.userId = query.GetParameterByName("userId");

      if (
        disp.IsNullorWhitespace(this.nonce) ||
        disp.IsNullorWhitespace(this.userId)
      ) {
        log.logError(
          `Missing nonce or userId values - nonce: ${this.nonce} userId: ${this.userId}`,
          "ConfirmEmailAction.mounted"
        );
        this.message =
          "Nonce or UserId was missing - please click the link in your email again.";
      }

      const confirmEmailResponse = await login.ConfirmEmail(
        this.nonce,
        this.userId
      );

      if (confirmEmailResponse.success) {
        this.message = "Email confirmed.";
        this.success = true;
      } else {
        this.message = confirmEmailResponse.displayText;
      }
    } catch (error) {
      log.logError(
        "Unhandled error in ConfirmEmailAction code",
        "ConfirmEmailAction.mounted"
      );
      log.logError(`${error.name}: ${error.message}`);
      this.message = "An unexpected error occured";
    } finally {
      this.loading = false;
    }
  },
  methods: {
    navTo(href) {
      this.$router.push({ path: href });
    },
  },
  computed: {},
};
</script>

<style scoped>
.pt-150 {
  padding-top: 150px;
}

.pt-75 {
  padding-top: 75px;
}
</style>
